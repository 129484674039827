import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../components/atoms/button";
import Loading from "../../../components/atoms/loading";
import Select from "../../../components/atoms/select";
import NavBar from "../../../components/organisms/navbar";
import rulesCopaMestreDeJiuJitsu2024 from "../../../data/rules-copa-amizade-de-jiu-jitsu-2024.json";

import useAuthenticate from "../../../hooks/useAuthenticate";

import api from "../../../services/api";
import { Content, FormContainer, PageTitle } from "../styles";
import SelectCategories from "src/components/atoms/selectCategories";


interface EnrollmentData {
  id: number;
  tournamentId: number;
  valueSubscription: string;
  age: string;
  belt: string;
  category: string;
  created_at: string;
  updated_at: string;
  status: boolean;
  fighters: null | any; // Define o tipo de 'fighters' como 'null' ou 'any'
  fightersId: null | any; // Define o tipo de 'fightersId' como 'null' ou 'any'
  genre: string;
  user: {
    id: number;
    name: string;
    email: string;
    birth_date: string;
    genre: string;
    gym: string;
    mother_name: string;
    status: boolean;
  };
  weight: string;
}
const EnrollmentUpdateCopaMestreAcaiDeJiuJitsu2024 = () => {
  const navigate = useNavigate();
  const { getToken, account } = useAuthenticate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { id,slug } = useParams();

  const url = window.location.href;

  const [data, setData] = useState([]);
  const [age, setAge] = useState({ title: 0, age: 0 });

  const [selectedData, setSelectedData] = useState<EnrollmentData>();

  const beltsData = [
    { title: "branca", option: 1 },
    { title: "cinza", option: 2 },
    { title: "amarela", option: 3 },
    { title: "laranja", option: 4 },
    { title: "verde", option: 5 },
    { title: "azul", option: 6 },
    { title: "roxa", option: 7 },
    { title: "marrom", option: 8 },
    { title: "preta", option: 9 },
  ];

  useEffect(() => {
    api
      .get("/tournaments/profile/enrollments", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        const sortedArray = data.sort((a: any, b: any) => a.id - b.id);
        setData(sortedArray);

        // Encontre o objeto com o ID correspondente e armazene-o em 'selectedData'
        const selected = sortedArray.find(
          (item: { id: number }) => item.id === Number(id)
        );

        setSelectedData(selected);
        if (selected) {
          if (selected.fighters) {
            console.log(selected)
            const [day, month, year] = selected.fighters.birth_date
              .split("/")
              .map(Number);
            const birthDate: Date = new Date(year, month - 1, day);
            const currentDate: Date = new Date();
            const timeDifference: number =
              currentDate.getFullYear() - birthDate.getFullYear();
            const ageFit = timeDifference;
            setAge({ title: ageFit, age: ageFit });


            setBelts([
              {
                title: selected.belt,
                option: beltsData.find((item) => item?.title === selected?.belt)
                  ?.option,
              },
            ]);
          } else {
            setAge({
              title: selected.age,
              age: selected.age,
            });
          }
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Não foi possível carregar os dados de inscrições.");
      });
  }, [id]);

  let rules = rulesCopaMestreDeJiuJitsu2024


  const [ages, setAges] = useState<any>([]);
  const [genre, setGenre] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [belts, setBelts] = useState<any>([]);
  const [weights, setWeights] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fighters, setFighters] = useState<any>();
  const [tournament, setTournament] = useState<any>({});

  let acc: any = account;
  let formattedGenre: String;

  let weightsSerialize: any = [];
  let categoriesSerialize: any = [];

  let arrCategories: any = [];
  let arrWeights: any = [];

  let fightingAge: Number;

  const handleChange = (e: any) => {
    setValue("category", "");
    setValue("weight", "");
    let genre: any = [];
    let belts: any = [];

    const arrGenre = [
      { title: "Masculino", option: "masculino" },
      { title: "Feminino", option: "feminino" },
    ];

    const arrBelts = [
      { title: "Branca", option: 1 },
      { title: "Cinza", option: 2 },
      { title: "Amarela", option: 3 },
      { title: "Laranja", option: 4 },
      { title: "Verde", option: 5 },
      { title: "Azul", option: 6 },
      { title: "Roxa", option: 7 },
      { title: "Marrom", option: 8 },
      { title: "Preta", option: 9 },
    ];

    // eslint-disable-next-line eqeqeq
    if (e.target.value == 0) {
      //============================== Genero ==============================
      if (selectedData) {
        genre = arrGenre.filter(
          (t) => t.option?.toLowerCase() === selectedData.genre?.toLowerCase()
        );
      } else {

        genre = arrGenre.filter(
          (t) => t.option?.toLowerCase() === acc.genre?.toLowerCase()
        );
      }

      formattedGenre = genre[0]?.option.toLowerCase();
      //============================== Idade ==============================
      // donst work with a valid date
      // // donst work with a valid date
      // const dateOfTournament = +tournament.date.replace(/\//g, "-").split("-")[0];

      const dateOfTournament = new Date().getFullYear();
      const birthFightFromFighter = +acc.birth_date
        ?.replace(/\//g, "-")
        .split("-")[2];
      fightingAge = dateOfTournament - birthFightFromFighter;

      //============================== Faixa ==============================
      belts = arrBelts.filter(
        (b) =>
          b.option ===
          beltsData.find((item) => item?.title == selectedData?.belt)?.option
      );
      //============================== Categoria ==============================
      // eslint-disable-next-line array-callback-return
      rules.map((item) => {
        if (
          item.idade.max >= +age.age &&
          item.idade.min <= +age.age &&
          item.genero === formattedGenre
        ) {
          arrCategories.push({
            item: item.categoria,
            min: item.idade.min,
            max: item.idade.max,
            maxDescricao: item.idade.maxParaDescricao,
            minDescricao: item.idade.minParaDescricao,
          });
        }
      });
      const uniqueArray = [];
      const itemSet = new Set();

      for (const obj of arrCategories) {
        if (!itemSet.has(obj.item)) {
          itemSet.add(obj.item);
          uniqueArray.push(obj);
        }
      }

      categoriesSerialize = [...new Set(uniqueArray)].map((category: any) => {
        return {
          title:
            `${category.item} - ${category.minDescricao} até ${category.maxDescricao} anos`.toUpperCase(),
          option: category.item,
        };
      });

      setValue("age", +fightingAge);
      setValue("category", "");
    } else {
      // ============================== Fighters ==============================
      // eslint-disable-next-line eqeqeq
      const fightersFilter: any = fighters.filter(
        (f: any) => +f.id === +e.target.value
      );

      //============================== Genero ==============================
      genre = arrGenre.filter(
        (t) => t.title.toLowerCase() === fightersFilter[0].genre.toLowerCase()
      );

      formattedGenre = genre[0].title.toLowerCase();
      //============================== Idade ==============================

      // donst work with a valid date
      // const dateOfTournament = +tournament.date.replace(/\//g, "-").split("-")[0];
      const dateOfTournament = new Date().getFullYear();
      const birthFightFromFighter = +fightersFilter[0].birth_date
        .replace(/\//g, "-")
        .split("-")[2];
      fightingAge = dateOfTournament - birthFightFromFighter;

      //============================== Faixa ==============================
      belts = arrBelts.filter(
        (b) =>
          b.option ===
          beltsData.find((item) => item?.title === selectedData?.belt)?.option
      );
      //============================== Categoria ==============================
      // eslint-disable-next-line array-callback-return
      rules.map((item) => {
        if (
          item.idade.max >= +fightingAge &&
          item.idade.min <= +fightingAge &&
          item.genero === formattedGenre
        ) {
          arrCategories.push({
            item: item.categoria,
            min: item.idade.min,
            max: item.idade.max,
            maxDescricao: item.idade.maxParaDescricao,
            minDescricao: item.idade.minParaDescricao,
          });
        }
      });

      const uniqueArray = [];
      const itemSet = new Set();

      for (const obj of arrCategories) {
        if (!itemSet.has(obj.item)) {
          itemSet.add(obj.item);
          uniqueArray.push(obj);
        }
      }

      categoriesSerialize = [...new Set(uniqueArray)].map((category: any) => {
        return {
          title:
            `${category.item} - ${category.minDescricao} até ${category.maxDescricao} anos`.toUpperCase(),
          option: category.item,
        };
      });

      setValue("category", "");
    }

    setGenre([...genre]);

    setAges([{ title: age.age, option: age.age }]);
    setBelts([...belts]);
    setTimeout(() => {
      setValue("genre", genre[0]?.option);
      setValue("age", age.age);
      setValue("belt", belts[0]?.option);
    }, 10);
    setCategories([
      {
        title: "Selecione uma opção",
        option: "",
      },
      ...categoriesSerialize,
    ]);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    handleChange({ target: { value: 0 } });
  }, [fighters]);

  const getTitleByOption = (array: any[], option: number) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].option === option) {
        return array[i].title;
      }
    }
    return null;
  };

  const onSubmit = (data: any) => {
    setDisabled(true);
    setLoading(true);

    const arrBelts = [
      { title: "branca", option: 1 },
      { title: "cinza", option: 2 },
      { title: "amarela", option: 3 },
      { title: "laranja", option: 4 },
      { title: "verde", option: 5 },
      { title: "azul", option: 6 },
      { title: "roxa", option: 7 },
      { title: "marrom", option: 8 },
      { title: "preta", option: 9 },
    ];
     
    
    console.log("antes------------------")
    console.log(data.belt)
    data.belt = getTitleByOption(arrBelts, Number(data.belt));
    console.log("depois------------------")
    console.log(data.belt)



    data.age = age.title.toString();
    delete data.option;
    api
      .patch(
        `/tournaments/enrollments/${id}`,
        {
          ...data,
          fightersId: selectedData?.fightersId!,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then(() => {
        toast.success(
          "A sua atualização da inscrição foi realizada com sucesso!"
        );

        setTimeout(() => {
          navigate("/profile/subscription");
        }, 2000);
      })
      .catch(() => {
        setDisabled(false);
        setLoading(false);
        toast.error("Não foi possível gerar a sua atualização da inscrição!");
      });
  };

  useEffect(() => {
    api
      .get("/fighters/" + id, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        setFighters([
          {
            id: "",
            name: "Selecione uma opção",
          },
          {
            id: 0,
            name: `${account.name}`,
          },
          ...data,
        ]);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Não foi carregar os dados.");
      });

    api.get("tournaments/" + slug).then(({ data }) => {
      setTournament(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  //============================== Peso ==============================
  const handleChangeWeights = (e: any) => {
    setValue("weight", "");
    // eslint-disable-next-line array-callback-return
    rules.map((item) => {
      if (
        item.idade.max >= +ages[0].option &&
        item.idade.min <= +ages[0].option &&
        item.genero === genre[0].title.toLowerCase() &&
        item.categoria === e.target.value
      ) {
        arrWeights.push({
          peso: item.peso.descricao,
          massa: item.peso.max,
        });
      }
    });



    weightsSerialize = [...new Set(arrWeights)].map((category: any) => {
      return {
        title: `${category.peso} - ${category.massa} `.toUpperCase(),
        option: category.peso,
      };
    });

    setWeights([
      {
        title: "Selecione uma opção",
        option: "",
      },
      ...weightsSerialize,
    ]);
  };


  function renderSelect(slug: string | undefined) {
   
        return (
          <Select
            label="Faixa"
            name="belt"
            register={register}
            required
            hasError={!!errors.belt}
            error="Este campo é obrigatório"
            options={belts}
          />
        )
  }

  if (loading)
    return <Loading text="Aguarde, estamos processando a sua pré-inscrição." />;

  return (
    <div>
      <NavBar />
      <Content>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <PageTitle>Atualização de Inscrição do Atleta {id}</PageTitle>
          <PageTitle>
            {selectedData?.fighters
              ? selectedData.fighters.name
              : selectedData?.user.name}
          </PageTitle>

          {/* <PageDescription>Atualização de inscrição</PageDescription> */}

          <Select
            label="Sexo"
            name="genre"
            register={register}
            value={selectedData?.genre}
            required
            hasError={!!errors.genre}
            error="Este campo é obrigatório"
            options={genre}
          />

          <Select
            label="Idade de luta"
            name="age"
            register={register}
            required
            value={age.title}
            hasError={!!errors.age}
            error="Este campo é obrigatório"
            options={ages}
          />

          {renderSelect(slug)}

          <SelectCategories
            label="Categoria"
            name="category"
            register={register}
            required
            hasError={!!errors.category}
            error="Este campo é obrigatório"
            options={categories}
            onChange={handleChangeWeights}
          />

          <Select
            label="Peso"
            name="weight"
            register={register}
            required
            hasError={!!errors.category}
            error="Este campo é obrigatório"
            options={weights}
          />
          <Button type="submit" label="Editar inscrição" disabled={disabled} />
        </FormContainer>
      </Content>
    </div>
  );
};
export default EnrollmentUpdateCopaMestreAcaiDeJiuJitsu2024;
