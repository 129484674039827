import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 0;
`;

export const PageTitle = styled.h1`
  margin: 0 0 14px 0;
  padding: 0;
`;

export const PageDescription = styled.p`
  margin: 0 0 40px 0;
  padding: 0;
`;

export const PaymentContainer = styled.form`
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
    }
    img {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
      display: block;

      @media (max-width: 768px) {
        max-width: 200px;

        margin: 0 auto 20px auto;

        display: block;

        &:last-child {
          margin-bottom: 0;

          display: block;

          max-width: 200px;

          margin: 0 auto;

          display: block;
        }
      }
    }
  }

  > a {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: rgb(255, 255, 255);
    text-decoration: none;
  }
`;
