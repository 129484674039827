import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logoImg from "../../../assets/images/logo.png";
import Button from "../../../components/atoms/button";
import Input from "../../../components/atoms/input";

import api from "../../../services/api";
import {
  AccountExists,
  Container,
  Content,
  FormContent,
  FormGroup,
  PageDescription,
  PageLogo,
  PageTitle,
  SectionBanner,
  SectionForm,
} from "./styles";

const PasswordRecovery: React.FC = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    api
      .post("/auth/password/recovery", {
        ...data,
      })
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message);

          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(() =>
        toast.error(
          "Não foi possível recuperar a sua senha, verifique seu e-mail!"
        )
      );
  };

  return (
    <Container>
      <SectionForm>
        <Content>
          <Link to={"/"}>
            <PageLogo src={logoImg} />
          </Link>
          <PageTitle>Recuperar senha</PageTitle>
          <PageDescription>
            Bem-vindo ao torneio fácil. Recupere <br />a sua conta preenchendo
            os dados abaixo.
          </PageDescription>

          <FormContent onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Input
                label="E-mail"
                name="email"
                type="text"
                register={register}
                required
                hasError={!!errors.email}
                error="Este campo é obrigatório"
                transformToLowerCase={true}
              />
            </FormGroup>
            <FormGroup>
              <Button type="submit" label="Enviar instruções" />
            </FormGroup>
            <FormGroup>
              <AccountExists href="/login">
                Já possui conta? Acesse aqui.
              </AccountExists>
            </FormGroup>
          </FormContent>
        </Content>
      </SectionForm>
      <SectionBanner></SectionBanner>
    </Container>
  );
};

export default PasswordRecovery;
