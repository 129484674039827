import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logoImg from "../../../assets/images/logo.png";

import Button from "../../../components/atoms/button";
import Input from "../../../components/atoms/input";
import InputMaskCustom from "../../../components/atoms/input-mask-custom";
import Select from "../../../components/atoms/select";
import api from "../../../services/api";
import {
  AccountExists,
  Container,
  Content,
  FormContent,
  FormGroup,
  PageDescription,
  PageLogo,
  PageTitle,
  SectionBanner,
  SectionForm,
  TermDescription,
} from "./styles";

const Register: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const navigate = useNavigate();

  const password = React.useRef({});
  password.current = watch("password", "");

  const belts = [
    { title: "Branca", option: 1 },
    { title: "Cinza", option: 2 },
    { title: "Amarela", option: 3 },
    { title: "Laranja", option: 4 },
    { title: "Verde", option: 5 },
    { title: "Azul", option: 6 },
    { title: "Roxa", option: 7 },
    { title: "Marrom", option: 8 },
    { title: "Preta", option: 9 },
  ]

  const onSubmit = (data: any) => {
    if (data.password !== data.confirmPassword) {
      return toast.error("A senha e a confirmação de senha devem ser iguais.");
    }

    delete data.confirmPassword;

    api
      .post("/auth/register", {
        ...data,
        belt: +data.belt
      })
      .then(() => {
        toast.success("A sua conta foi criada com sucesso!");

        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch(() => {
        toast.error("Não foi possível criar a sua conta!");
      });
  };

  return (
    <Container>
      <SectionForm>
        <Content>
          <Link to={"/"}>
            <PageLogo src={logoImg} />
          </Link>
          <PageTitle>Cadastre-se</PageTitle>
          <PageDescription>
            Bem-vindo ao Torneio Fácil. Vamos realizar <br />o seu cadastro?
            Preencha os dados abaixo.
          </PageDescription>
          <FormContent onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Input
                label="Nome completo"
                name="name"
                type="text"
                register={register}
                required
                hasError={!!errors.name}
                error="Este campo é obrigatório"
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="Nome da mãe"
                name="mother_name"
                type="text"
                register={register}
                required
                hasError={!!errors.mother_name}
                error="Este campo é obrigatório"
              />
            </FormGroup>
            <FormGroup>
              <InputMaskCustom
                label="Telefone"
                name="phone"
                mask="(99) 99999-9999"
                register={register}
                required
                hasError={!!errors.phone}
                error="Este campo é obrigatório"
              />
            </FormGroup>
            <FormGroup>
              <InputMaskCustom
                label="Data de nascimento"
                name="birth_date"
                mask="99/99/9999"
                register={register}
                required
                hasError={!!errors.birth_date}
                error="Este campo é obrigatório"
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="Academia"
                name="gym"
                type="text"
                register={register}
                required
                hasError={!!errors.gym}
                error="Este campo é obrigatório"
              />
            </FormGroup>
            <FormGroup>
              <Select
                label="Faixa"
                name="belt"
                register={register}
                required
                hasError={!!errors.belt}
                error="Este campo é obrigatório"
                options={belts}
              />
            </FormGroup>
            <FormGroup>
              <Select
                label="Sexo"
                name="genre"
                register={register}
                required
                hasError={!!errors.genre}
                error="Este campo é obrigatório"
                options={[
                  { title: "Masculino", option: "masculino" },
                  { title: "Feminino", option: "feminino" },
                ]}
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="E-mail"
                name="email"
                type="text"
                register={register}
                required
                hasError={!!errors.email}
                error="Este campo é obrigatório"
                transformToLowerCase={true}
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="Senha"
                name="password"
                type="password"
                register={register}
                required
                hasError={!!errors.password}
                error="Este campo é obrigatório"
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="Confirmar senha"
                name="confirmPassword"
                type="password"
                register={register}
                required
                hasError={!!errors.confirmPassword}
                error="Este campo é obrigatório"
              />
            </FormGroup>

            <FormGroup>
              <TermDescription>
                Ao criar a sua conta você estará de acordo com a política e
                termos de uso do Torneio Fácil.
              </TermDescription>
            </FormGroup>
            <FormGroup>
              <Button type="submit" label="Cadastrar" />
            </FormGroup>
            <FormGroup>
              <AccountExists href="/login">
                Já possui conta? Entre aqui
              </AccountExists>
            </FormGroup>
          </FormContent>
        </Content>
      </SectionForm>
      <SectionBanner />
    </Container>
  );
};

export default Register;
