import React, { useEffect } from "react";
import { Field, InputContainer, Label, ValidateHelp } from "./styles";

type InputProps = {
  label: string;
  type:
    | "text"
    | "password"
    | "number"
    | "email"
    | "tel"
    | "date"
    | "time"
    | "datetime-local"
    | "hidden"
    | "month"
    | "week"
    | "url"
    | "search"
    | "color"
    | "range"
    | "file"
    | "image"
    | "radio"
    | "checkbox"
    | "button"
    | "submit"
    | "reset"
    | "textarea"
    | "select"
    | "select-multiple"
    | "select-one"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week"
    | "textarea";
  placeholder?: string;
  value?: string | number;
  name?: string;
  hasError?: boolean;
  error?: string;
  register?: any;
  required: any;
  transformToLowerCase?: boolean; // adicionado
};

const Input: React.FC<InputProps> = ({
  label,
  type = "text",
  placeholder,
  value,
  name,
  hasError,
  error,
  register,
  required,
  transformToLowerCase, // adicionado
}) => {
  const [messageError, setMessageError] = React.useState("");

  useEffect(() => {
    if (hasError && error) {
      setMessageError(error);
    } else {
      setMessageError("");
    }
  }, [hasError, error]);

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    if (transformToLowerCase) {
      event.currentTarget.value = event.currentTarget.value.toLowerCase();
    }
  };

  return (
    <InputContainer>
      <Label>{label}</Label>
      <Field
        hasError={hasError && error}
        type={type}
        value={value}
        placeholder={placeholder}
        {...register(name, { required })}
        onInput={handleInput} // adicionado
      />
      <ValidateHelp>{messageError || ""}</ValidateHelp>
    </InputContainer>
  );
};

export default Input;
