import { useParams } from "react-router-dom";
import AllCheckSeletivaManausOlimpica2024 from "./tournament/check-seletiva-manaus-olimpica-2024";
import AllCheckViiiReiERainhaDaPraiaBeachWrestilingManausAmazonas2024 from "./tournament/check-viii-rei-e-rainha-da-praia-beach-wrestiling-manaus-amazonas-2024";
import AllCheckCopaAmizadeDeJiuJitsu2024 from "./tournament/check-copa-amizade-de-jiu-jitsu-2024";
import AllCheckCopaMestreAcaiDeJiuJitsu2024 from "./tournament/check-copa-mestre-acai-de-jiu-jitsu-2024y";
import AllCheckXxxiiCopaOrleyLobatoDeJiuJitsu from "./tournament/check-xxxii-copa-orley-lobato-de-jiu-jitsu";

function AllCheck(){
  const { slug } = useParams();
  switch(slug){
    case 'seletiva-manaus-olimpica-2024-ginasio-ninimberg-guerra-sao-jorge-manaus-am':
      return <AllCheckSeletivaManausOlimpica2024 />
      
    case 'viii-rei-e-rainha-da-praia-beach-wrestling-2024-nossa-praia-sports':
       return  <AllCheckViiiReiERainhaDaPraiaBeachWrestilingManausAmazonas2024 />
    case 'copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr':
      return  <AllCheckCopaAmizadeDeJiuJitsu2024 />
    case 'copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr':
      return  <AllCheckCopaMestreAcaiDeJiuJitsu2024/>
    case 'xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am':
      return  <AllCheckXxxiiCopaOrleyLobatoDeJiuJitsu/>
  
    default:
      return <h1>Checagem para esse Torneio não encontrado!</h1>
  }

}

export default AllCheck;
