import { useEffect, useState } from "react";
import NavBar from "../../components/organisms/navbar";
import {
  Container,
  Content,
  InfoContainerStatus,
  InfoContainerText,
  PageLocationTitle,
  PageTitle,
  PageTitleContainer,
  StatusButton,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionDescription,
  SubscriptionItem,
  SubscriptionList,
  SubscriptionTitle,
} from "../profile/subscriptions/styles";
import api from "../../services/api";
import { toast } from "react-toastify";
import useAuthenticate from "../../hooks/useAuthenticate";
import Loading from "../../components/atoms/loading";
import React from "react";

import { useParams } from "react-router-dom";
import { StringToSlug, StringToSlugPraia } from "../../utils/SlugConverter";
import AllCheckCategoriesSeletivaManausOlimpica2024 from "./tournament/all-check-category-seletiva-manaus-olimpica-2024";
import AllCheckCategoriesCheckViiiReiERainhaDaPraiaBeachWrestiling2024 from "./tournament/all-check-category-viii-rei-e-rainha-da-praia-beach-wrestiling-manaus-amazonas-2024";
import AllCheckCategoriesCopaAmizadeDeJiuJitsu2024 from "./tournament/all-check-category-copa-amizade-de-jiu-jitsu-2024";
import AllCheckCategoriesCopaMestreAcaiDeJiuJitsu2024 from "./tournament/all-check-category-copa-mestre-acai-de-jiu-jitsu-2024";
import AllCheckCategoriesXxxiiCopaOrleyLobatoDeJiuJitsu from "./tournament/all-check-category-xxxii-copa-orley-lobato-de-jiu-jitsu";

const AllCheckCategories = () => {


  const { slug } = useParams();
  
  switch(slug){
    case 'seletiva-manaus-olimpica-2024-ginasio-ninimberg-guerra-sao-jorge-manaus-am':
      return <AllCheckCategoriesSeletivaManausOlimpica2024 />
      
    case 'viii-rei-e-rainha-da-praia-beach-wrestling-2024-nossa-praia-sports':
       return  <AllCheckCategoriesCheckViiiReiERainhaDaPraiaBeachWrestiling2024 />
    
    case 'copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr':
      return  <AllCheckCategoriesCopaAmizadeDeJiuJitsu2024 />

      case 'copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr':
        return  <AllCheckCategoriesCopaMestreAcaiDeJiuJitsu2024/>
      case 'xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am':
        return  <AllCheckCategoriesXxxiiCopaOrleyLobatoDeJiuJitsu/>
  
    default:
      return <h1>Checagem para esse Torneio não encontrado!</h1>
  }
}

export default AllCheckCategories;
