import { useEffect, useState } from "react";
import NavBar from "../../../components/organisms/navbar";
import {
  Container,
  Content,
  InfoContainerStatus,
  InfoContainerText,
  Loader,
  PageLocationTitle,
  PageTitle,
  PageTitleContainer,
  StatusButton,
  SubscriptionActions,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionDescription,
  SubscriptionItem,
  SubscriptionLink,
  SubscriptionList,
  SubscriptionTitle,
} from "./styles";
import api from "../../../services/api";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";
import Loading from "../../../components/atoms/loading";
import {
  FilterOption,
  FilterSelect,
} from "src/components/atoms/input/inputText";
import { useNavigate } from "react-router-dom";

const Subscription = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<string | null>(null);
  const { getToken } = useAuthenticate();
  const [tournaments, setTournaments] = useState<any[]>([]);
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
  const [selectedEnrollment, setSelectedEnrollment] = useState<number | null>(null);

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true);
    api.get("/tournaments").then(({ data }) => {
      setTournaments(data);
    });

    api
      .get("/tournaments/profile/enrollments", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        const sortedArray = data.sort((a: any, b: any) => a.id - b.id);
        setData(sortedArray);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Não foi carregar os dados de inscrições.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilter = (event: any) => {
    const value = event.target.value;
    if (value === "1") {
      setFilter("1");
    } else {
      setFilter(value === "" ? null : value || "asas");
    }
  };

  const filteredData = filter
    ? data.filter((item: any) => {
      if (filter === "1") {
        return item.tournamentId === 1;
      } else {
        return item.tournamentId === parseInt(filter) || !item.tournamentId;
      }
    })
    : [];

  if (loading) return <Loading text="Aguarde, estamos processando os dados." />;

  const redirectToPagePayment = (id: number, tournamentId: number) => {
    const tournament = tournaments.find((tournament) =>
      tournament.id === tournamentId
    );

    if (!(tournament?.paymentPix || tournament?.paymentCredicard)) {
      navigate(`/payment-instruction/${tournament?.slug}/${id}`);
    }

    setSelectedEnrollment(id);

    setIsPaymentLoading(true);

    api.get(`/payments/checkout/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    ).then(res => {
      window.open(res.data.links[1].href, "_self");
    }).catch(err => {
      console.log(err)
      setIsPaymentLoading(false);
    });

  }

  return (
    <>
      <NavBar />
      <Container>
        <Content>
          <PageTitleContainer>
            <PageLocationTitle>Minhas Inscrições</PageLocationTitle>
            <PageTitle>{filteredData.length} inscrições encontradas</PageTitle>

            <FilterSelect selectedCategory={filter} onChange={handleFilter}>
              <FilterOption value="">SELECIONE UM CAMPEONATO</FilterOption>
              {tournaments.filter(t => data.some((d: any) => d.tournamentId === t.id)).map((tournament) => (
                <FilterOption value={tournament.id}>
                  {tournament.name.toUpperCase()}
                </FilterOption>
              ))}
            </FilterSelect>
          </PageTitleContainer>
          <br />
          <SubscriptionContainer>
            {filteredData &&
              filteredData
                .slice(0)
                .reverse()
                .map((value: any) => (
                  <SubscriptionList key={value.id}>
                    <SubscriptionItem>
                      <SubscriptionContent>
                        <SubscriptionTitle>
                          Inscrição Nº {value.id} -{" "}
                          {tournaments
                            .find(
                              (tournament) =>
                                tournament.id === value.tournamentId
                            )
                            ?.name.toUpperCase()}
                        </SubscriptionTitle>
                        <br />
                        <SubscriptionDescription>
                          <InfoContainerText>
                            <span>
                              <b>Nome:</b>{" "}
                              {value.fighters
                                ? value.fighters.name
                                : value.user.name}
                            </span>
                            <span>
                              <b>Equipe: </b>{" "}
                              {value.fighters
                                ? value.fighters.gym
                                : value.user.gym}
                            </span>
                            {/* <span>Professor: ORLEY LOBATO</span><br/> */}
                            <span>
                              <b>Categoria(s):</b> {value.genre.toUpperCase()} (
                              {value.category.toUpperCase()}) /{" "}
                              {value.belt.toUpperCase()} / {value.weight}
                            </span>
                          </InfoContainerText>
                          <InfoContainerStatus>
                            <StatusButton status={value.status}>
                              <span>
                                {value.status
                                  ? "Pagamento efetuado"
                                  : "Pendente de pagamento"}
                              </span>
                            </StatusButton>
                          </InfoContainerStatus>
                        </SubscriptionDescription>
                        <SubscriptionActions>
                          <SubscriptionLink
                            onClick={() => redirectToPagePayment(value.id, value.tournamentId)}
                            to={""}
                          >
                            {isPaymentLoading && selectedEnrollment === value.id ? <div style={{
                              minWidth: "110px",
                              textAlign: "center"
                            }}>
                              <Loader></Loader>
                            </div> :
                              "2ª via Pagamento"}
                          </SubscriptionLink>
                          {value.tournamentId === 1 ? (
                            <SubscriptionLink to={`#`}>
                              Edições encerradas
                            </SubscriptionLink>
                          ) : value.status ? (
                            <SubscriptionLink
                              to={`/enrollment-update/${value.id}/${tournaments.find(
                                (tournament) =>
                                  tournament.id === value.tournamentId
                              )?.slug
                                }`}
                            >
                              Alterar Inscrição
                            </SubscriptionLink>
                          ) : (
                            <SubscriptionLink
                              disabled={true}
                              to={`/profile/subscription`}
                            >
                              Alterar Inscrição (Pagamento Pendente)
                            </SubscriptionLink>
                          )}
                          <SubscriptionLink to="#">
                            Termos de Condição (EM BREVE)
                          </SubscriptionLink>
                        </SubscriptionActions>
                      </SubscriptionContent>
                    </SubscriptionItem>
                  </SubscriptionList>
                ))}
          </SubscriptionContainer>
        </Content>
      </Container>
    </>
  );
};

export default Subscription;
