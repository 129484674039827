import { useEffect, useState } from "react";
import api from "../../../services/api";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";

import Input from "../../../components/atoms/input";
import Loading from "../../../components/atoms/loading";
import NavBar from "../../../components/organisms/navbar";
import Button from "../../../components/atoms/button";

import { FormGroup, Content, PageTitle, FormContainer } from "./styles";
import InputMaskCustom from "../../../components/atoms/input-mask-custom";
import Select from "../../../components/atoms/select";

export const EditUser = () => {
  const navigate = useNavigate();
  const { getToken } = useAuthenticate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [birth_date, setBirth_date] = useState("")
  const [phone, setPhone] = useState("")

  const belts = [
    { title: "Branca", option: 1 },
    { title: "Cinza", option: 2 },
    { title: "Amarela", option: 3 },
    { title: "Laranja", option: 4 },
    { title: "Verde", option: 5 },
    { title: "Azul", option: 6 },
    { title: "Roxa", option: 7 },
    { title: "Marrom", option: 8 },
    { title: "Preta", option: 9 },
  ]

  const onSubmit = (data: any) => {
    setDisabled(true);
    setLoading(true);

    api
      .put(
        `/me`,
        {
          ...data,
          belt: +data.belt
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then(() => {
        toast.success("Atleta foi atualizado com sucesso!");

        setTimeout(() => {
          navigate("/profile");
        }, 2000);
      })
      .catch(() => {
        setDisabled(false);
        setLoading(false);
        toast.error("Não foi possível atualizar atleta!");
      });
  };

  const fetchUser = async () => {
    setLoading(true)
    await api
      .get(`/me`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        setValue("name", response.data.name);
        setValue("mother_name", response.data.mother_name);
        setValue("gym", response.data.gym);
        setValue("genre", response.data.genre);
        setPhone(response.data.phone)
        setBirth_date(response.data.birth_date)
        setValue("phone", response.data.phone)
        setValue("birth_date", response.data.birth_date)
        setValue("belt", response.data.belt)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
      });
  };


  useEffect(() => {
    setPhone("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("phone")])

  useEffect(() => {
    setBirth_date("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("birth_date")])

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return <Loading text="Aguarde, estamos processando suas informações." />;

  return (
    <>
      <NavBar />
      <Content>
        <PageTitle>Edição de Perfil</PageTitle>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Input
              label="Nome"
              name="name"
              type="text"
              register={register}
              required
              hasError={!!errors.name}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Nome da mãe"
              name="mother_name"
              type="text"
              register={register}
              required
              hasError={!!errors.mother_name}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Select
              label="Sexo"
              name="genre"
              register={register}
              required
              hasError={!!errors.genre}
              error="Este campo é obrigatório"
              options={[
                { title: "Masculino", option: "Masculino" },
                { title: "Feminino", option: "Feminino" },
              ]}
            />
          </FormGroup>
          <FormGroup>
            <InputMaskCustom
              label="Telefone"
              name="phone"
              mask="(99) 99999-9999"
              register={register}
              required
              placeholder={phone}
              hasError={!!errors.phone}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <InputMaskCustom
              label="Data de nascimento"
              name="birth_date"
              mask="99/99/9999"
              placeholder={birth_date}
              register={register}
              required
              hasError={!!errors.birth_date}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Input
              label="Academia"
              name="gym"
              type="text"
              register={register}
              required
              hasError={!!errors.gym}
              error="Este campo é obrigatório"
            />
          </FormGroup>
          <FormGroup>
            <Select
              label="Faixa"
              name="belt"
              register={register}
              required
              hasError={!!errors.belt}
              error="Este campo é obrigatório"
              options={belts}
            />
          </FormGroup>
          <Button type="submit" label="Atualizar perfil" disabled={disabled} />
        </FormContainer>
      </Content>
    </>
  );
};
