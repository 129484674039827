
import { useParams } from "react-router-dom";
import _1copaArenaParintinsDeJiuJitsu from "../../data/rules-1-copa-arena-parintins-de- jiu-jitsu.json";
import EnrollmentUpdateSeletivaManausOlimpica2024 from "./tournament/enrollment-update-seletiva-manaus-olimpica-2024";
import EnrollmentUpdateViiiReiERainhaDaPraiaBeachWrestiling2024 from "./tournament/enrollment-update-viii-rei-e-rainha-da-praia-beach-wrestiling-manaus-amazonas-2024";
import EnrollmentUpdateCopaAmizadeDeJiuJitsu2024 from "./tournament/enrollment-update-copa-amizade-de-jiu-jitsu-2024";
import EnrollmentUpdateCopaMestreAcaiDeJiuJitsu2024 from "./tournament/enrollment-update-copa-mestre-acai-de-jiu-jitsu-2024";
import EnrollmentUpdateXxxiiCopaOrleyLobatoDeJiuJitsu from "./tournament/enrollment-update-xxxii-copa-orley-lobato-de-jiu-jitsu";

const EnrollmentUpdate = () => {
  const { slug } = useParams();
  console.log(slug)

  switch(slug){
    case 'seletiva-manaus-olimpica-2024-ginasio-ninimberg-guerra-sao-jorge-manaus-am':
      return <EnrollmentUpdateSeletivaManausOlimpica2024/>
    case 'viii-rei-e-rainha-da-praia-beach-wrestling-2024-nossa-praia-sports':
      return  <EnrollmentUpdateViiiReiERainhaDaPraiaBeachWrestiling2024/>
    case 'copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr':
    return  <EnrollmentUpdateCopaAmizadeDeJiuJitsu2024/>
    case 'copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr':
      return  <EnrollmentUpdateCopaMestreAcaiDeJiuJitsu2024/>
    case 'xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am':
      return  <EnrollmentUpdateXxxiiCopaOrleyLobatoDeJiuJitsu/>
    default:
    return <h1>Inscrição para esse Torneio não encontrado!</h1>
}
}
export default EnrollmentUpdate;
