import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logoImg from "../../../assets/images/logo.png";
import Button from "../../../components/atoms/button";
import Input from "../../../components/atoms/input";
import useAuthenticate from "../../../hooks/useAuthenticate";

import api from "../../../services/api";
import {
  AccountExists,
  Container,
  Content,
  FormContent,
  FormGroup,
  PageDescription,
  PageLogo,
  PageTitle,
  PasswordRecovery,
  SectionBanner,
  SectionForm,
} from "./styles";

const Login: React.FC = () => {
  const { setToken } = useAuthenticate();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    api
      .post("/auth/login", {
        ...data,
      })
      .then((response) => {
        if (response.data.status) {
          toast.success(response.data.message);

          setToken(response.data.accessToken);

          setTimeout(() => {
            navigate("/profile");
          }, 2000);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch(() =>
        toast.error(
          "Não foi possível acessar a sua conta, verifique seu e-mail ou senha!"
        )
      );
  };

  return (
    <Container>
      <SectionForm>
        <Content>
          <Link to={"/"}>
            <PageLogo src={logoImg} />
          </Link>
          <PageTitle>Entrar</PageTitle>
          <PageDescription>
            Bem-vindo ao Torneio Fácil, acesse <br />a sua conta preenchendo os
            dados abaixo.
          </PageDescription>

          <FormContent onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Input
                label="E-mail"
                name="email"
                type="text"
                register={register}
                required
                hasError={!!errors.email}
                error="Este campo é obrigatório"
                transformToLowerCase={true}
              />
            </FormGroup>
            <FormGroup>
              <Input
                label="Senha"
                name="password"
                type="password"
                register={register}
                required
                hasError={!!errors.password}
                error="Este campo é obrigatório"
              />
            </FormGroup>
            <FormGroup>
              <PasswordRecovery href="/password-recovery">
                Esqueceu a sua senha? <strong>Recupere aqui.</strong>
              </PasswordRecovery>
            </FormGroup>
            <FormGroup>
              <Button type="submit" label="Entrar" />
            </FormGroup>
            <FormGroup>
              <AccountExists href="/register">
                Ainda não possui conta? Cadastre-se aqui
              </AccountExists>
            </FormGroup>
          </FormContent>
        </Content>
      </SectionForm>
      <SectionBanner></SectionBanner>
    </Container>
  );
};

export default Login;
