import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
`;

export const Label = styled.label`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #33303e;
  text-align: left;
`;

export const Field = styled.input`
  border: 1px solid rgba(140, 138, 151, 0.4);
  border-radius: 8px;
  padding: 14px;

  ${({ hasError }: { hasError: boolean }) =>
    hasError && "border-color: #ff5e5e !important;"}
`;

export const ValidateHelp = styled.p`
  text-align: left;
  font-size: 13px;
  margin: 8px 0;
  color: #ff5e5e;
`;
