import NavBar from "../../components/organisms/navbar";
import { Content, PaymentContainer } from "./styles";
import { FaWhatsapp } from "react-icons/fa";
import qrCode from "../../assets/images/qrCode.png";

import qrCodeCopaParana from "../../assets/images/tournaments/xxvi-copa-parana/qrCodeCopaParana.png";
import qrCodeOrlay from "../../assets/images/tournaments/xxxi-copa-orley-lobato/qrcodeOrlayLobato.png";

import { useParams } from "react-router-dom";

const PaymentEnrollment = () => {
  const { slug, id } = useParams();
  console.log(slug);

  switch (slug) {
    case "xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am":
        return (
          <div>
            <NavBar />
  
            <Content>
              <PaymentContainer>
                <div style={{ textAlign: "center" }}>
                  <h2 style={{ textAlign: "center" }}>
                    Obrigado por fazer a sua inscrição na: <br />{" "}
                    {slug === "xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am"
                      ? "XXXII COPA ORLEY LOBATO DE JIU JITSU"
                      : "TOLEDO INTERNATIONAL OPEN NO GI"}
                  </h2>
                  <h2 style={{ color: "red" }}>Inscrição N° {id}</h2>
                  {/* <p>Temos algumas instruções de pagamentos para você:</p> */}
                  <h1>Instruções:</h1>
                  <h3>
                    1° Efetuar o pagamento através do PIX:{" "}
                    <ul>
                      <li>
                        <strong>NuBank</strong>
                      </li>
                      <li>Thiago Souza Dellarmelina</li>
                      <li>
                        chave PIX do tipo telefone <strong>92 98129-1027</strong>
                      </li>
                    </ul>
                    {/* <strong>
                    
                      (45) 99907-3832
                    
                  </strong>{" "} */}
                    <br />
                    2° Enviar o comprovante de pagamento para o WhatsApp:{" "}
                    <strong>
                      <br />
                      {slug === "xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am" ? (
                        <a
                          target={"blank"}
                          href={`https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago+Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2AXXXII+COPA+ORLEY+LOBATO+DE+JIU+JITSU%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                        >
                          (92) 98129-1027
                          <FaWhatsapp />
                        </a>
                      ) : (
                        <a
                          target={"blank"}
                          href={`https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago+Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2AXXXII+COPA+ORLEY+LOBATO+DE+JIU+JITSU%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                        >
                          (92) 98129-1027
                          <FaWhatsapp />
                        </a>
                      )}
                    </strong>{" "}
                    <br />
                    <br />
                    3° Enviar o print do numero de inscrição que você pagou para o WhatsApp:{" "}
                    <strong>
                      <br />
                      {slug === "xxxii-copa-orley-lobato-de-jiu-jitsu-cel-nininberg-guerra-manaus-am" ? (
                        <a
                          target={"blank"}
                          href={`https://whatsa.me/5592981291027/?t=Ol%C3%A1+Vander+Thiago+Dellarmelina%2C+vou+enviar+o+print+referente+a+inscri%C3%A7%C3%A3o+no+%2XXXII+COPA+ORLEY+LOBATO+DE+JIU+JITSU%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                        >
                          (92) 98129-1027
                          <FaWhatsapp />
                        </a>
                      ) : (
                        <a
                          target={"blank"}
                          href={`https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago+Dellarmelina%2C+vou+enviar+o+print++referente+a+inscri%C3%A7%C3%A3o+no+%2AXXXII+COPA+ORLEY+LOBATO+DE+JIU+JITSU%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                        >
                          (92) 98129-1027
                          <FaWhatsapp />
                        </a>
                      )}
                    </strong>{" "}
                    <br />
                    <br />
                    4° Acompanhe o status do pagamento em: <br />
                    <a href="/profile/subscription">Minhas Inscrições</a>{" "}
                  </h3>
                  {/* <img src={qrCodeCopaParana} alt="qrCodeCopaParana" /> */}
                  {/* <p>
                  Você deve enviar o comprovante de pagamento e o numero de inscrição <strong>{id}</strong> para {" "}
                  <strong>
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                    >
                      (45) 99907-3832
                      <FaWhatsapp />
                    </a>
                  </strong>
                </p> */}
                </div>
              </PaymentContainer>
            </Content>
          </div>
        );

case "copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr":
        return (
          <div>
            <NavBar />
  
            <Content>
              <PaymentContainer>
                <div style={{ textAlign: "center" }}>
                  <h2 style={{ textAlign: "center" }}>
                    Obrigado por fazer a sua inscrição na: <br />{" "}
                    {slug ===
                    "copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr"
                      ? "COPA MESTRE AÇAI DE JIU JITSU 2024"
                      : "TOLEDO INTERNATIONAL OPEN NO GI"}
                  </h2>
                  <h2 style={{ color: "red" }}>Inscrição N° {id}</h2>
                  {/* <p>Temos algumas instruções de pagamentos para você:</p> */}
                  <h1>Instruções:</h1>
                  <h3>
                    1° Efetuar o pagamento através do PIX:{" "}
                    <ul>
                      <li>
                        <strong>NuBank</strong>
                      </li>
                      <li>Thiago Souza Dellarmelina</li>
                      <li>
                        chave PIX do tipo telefone <strong>92 98129-1027</strong>
                      </li>
                    </ul>
                    {/* <strong>
                    
                      (45) 99907-3832
                    
                  </strong>{" "} */}
                    <br />
                    2° Enviar o comprovante de pagamento para o WhatsApp:{" "}
                    <strong>
                      <br />
                      {slug === "copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr" ? (
                        <a
                          target={"blank"}
                          href={`https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago+Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+MESTRE+AÇAI+DE+JIU+JITSU+2024%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                        >
                          (92) 98129-1027
                          <FaWhatsapp />
                        </a>
                      ) : (
                        <a
                          target={"blank"}
                          href={`https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago+Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+MESTRE+AÇAI+DE+JIU+JITSU+2024%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                        >
                          (92) 98129-1027
                          <FaWhatsapp />
                        </a>
                      )}
                    </strong>{" "}
                    <br />
                    <br />
                    3° Enviar o print do numero de inscrição que você pagou para o WhatsApp:{" "}
                    <strong>
                      <br />
                      {slug === "copa-mestre-acai-de-jiu-jitsu-2024-ginasio-arrudao-francisco-beltao-pr" ? (
                        <a
                          target={"blank"}
                          href={`https://whatsa.me/5592981291027/?t=Ol%C3%A1+Vander+Thiago+Dellarmelina%2C+vou+enviar+o+print+referente+a+inscri%C3%A7%C3%A3o+no+%2COPA+MESTRE+AÇAI+DE+JIU+JITSU+2024%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                        >
                          (92) 98129-1027
                          <FaWhatsapp />
                        </a>
                      ) : (
                        <a
                          target={"blank"}
                          href={`https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago+Dellarmelina%2C+vou+enviar+o+print++referente+a+inscri%C3%A7%C3%A3o+no+%2ACOPA+MESTRE+AÇAI+DE+JIU+JITSU+2024%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                        >
                          (92) 98129-1027
                          <FaWhatsapp />
                        </a>
                      )}
                    </strong>{" "}
                    <br />
                    <br />
                    4° Acompanhe o status do pagamento em: <br />
                    <a href="/profile/subscription">Minhas Inscrições</a>{" "}
                  </h3>
                  {/* <img src={qrCodeCopaParana} alt="qrCodeCopaParana" /> */}
                  {/* <p>
                  Você deve enviar o comprovante de pagamento e o numero de inscrição <strong>{id}</strong> para {" "}
                  <strong>
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                    >
                      (45) 99907-3832
                      <FaWhatsapp />
                    </a>
                  </strong>
                </p> */}
                </div>
              </PaymentContainer>
            </Content>
          </div>
        );







    case "seletiva-manaus-olimpica-2024-ginasio-ninimberg-guerra-sao-jorge-manaus-am":
      return (
        <div>
          <NavBar />

          <Content>
            <PaymentContainer>
              <div style={{ textAlign: "center" }}>
                <h2 style={{ textAlign: "center" }}>
                  Obrigado por fazer a sua inscrição na: <br />
                  {"SELETIVA MANAUS OLIMPICA 2024"}
                </h2>
                <h2 style={{ color: "red" }}>Inscrição N° {id}</h2>
                {/* <p>Temos algumas instruções de pagamentos para você:</p> */}
                <h1>Instruções:</h1>
                <h3>
                <h2 style={{ color: "red" }}>O STATUS DE PAGAMENTO SERÁ ATUALIZADO PARA PAGO EM ATÉ 10 HORAS 
                PELO ADMINSTRADOR DO EVENTO, FIQUE TRANQUILO QUE A SUA INSCRIÇÃO SERÁ REGULARIZADA EM BREVE</h2>

                  1° Acompanhe o status da sua inscrição em: <br />
                  <a href="/profile/subscription">Minhas Inscrições</a>{" "}
                </h3>
                <h3>
                  2° Fique atento ao edidal para não ser desclasificado: <br />
                 
                </h3>
                {/* <img src={qrCodeCopaParana} alt="qrCodeCopaParana" /> */}
                {/* <p>
                Você deve enviar o comprovante de pagamento e o numero de inscrição <strong>{id}</strong> para {" "}
                <strong>
                  <a
                    target={"blank"}
                    href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                  >
                    (45) 99907-3832
                    <FaWhatsapp />
                  </a>
                </strong>
              </p> */}
              </div>
            </PaymentContainer>
          </Content>
        </div>
      );





    case "viii-rei-e-rainha-da-praia-beach-wrestling-2024-nossa-praia-sports":
      return (
        <div>
          <NavBar />

          <Content>
            <PaymentContainer>
              <div style={{ textAlign: "center" }}>
                <h2 style={{ textAlign: "center" }}>
                  Obrigado por fazer a sua inscrição no: <br />
                  {"VIII REI E RAINHA DA PRAIA - BEACH WRESTLING 2024"}
                </h2>
                <h2 style={{ color: "red" }}>Inscrição N° {id}</h2>
                {/* <p>Temos algumas instruções de pagamentos para você:</p> */}
                <h1>Instruções:</h1>
                <h3>
                  1° Efetuar o pagamento através do PIX:{" "}
                  <ul>
                    <li>
                      <strong>Itau</strong>
                    </li>
                    <li>Tasso Oliveira Alves da Rocha</li>
                    <li>
                      chave PIX do tipo telefone: <strong>(92) 98182-1004</strong>
                    </li>
                  </ul>
                  {/* <strong>
                  
                    (45) 99907-3832
                  
                </strong>{" "} */}
                  <br />
                  2° Enviar o comprovante de pagamento para o WhatsApp:{" "}
                  <strong>
                    <br />
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/5592981821004/?t=Ol%C3%A1%20Tasso%20Oliveira%20Alves%20da%20Rocha,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*I%20REI%20E%20RAINHA%20DA%20PRAIA%20-%20BEACH%20WRESTLING+2024*%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                    >
                      (92) 98182-1004
                      <FaWhatsapp />
                    </a>
                  </strong>{" "}
                  <br />
                  <br />
                  3° Enviar o print do numero de inscrição que você pagou para o WhatsApp:{" "}
                  <strong>
                    <br />
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/5592981821004/?t=Ol%C3%A1%20Tasso%20Oliveira%20Alves%20da%20Rocha,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*I%20REI%20E%20RAINHA%20DA%20PRAIA%20-%20BEACH%20WRESTLING+2024*%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                    >
                      (92) 98182-1004
                      <FaWhatsapp />
                    </a>
                  </strong>{" "}
                  <br />
                  <br />
                  4° Acompanhe o status do pagamento em: <br />
                  <a href="/profile/subscription">Minhas Inscrições</a>{" "}
                </h3>
                {/* <img src={qrCodeCopaParana} alt="qrCodeCopaParana" /> */}
                {/* <p>
                Você deve enviar o comprovante de pagamento e o numero de inscrição <strong>{id}</strong> para {" "}
                <strong>
                  <a
                    target={"blank"}
                    href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                  >
                    (45) 99907-3832
                    <FaWhatsapp />
                  </a>
                </strong>
              </p> */}
              </div>
            </PaymentContainer>
          </Content>
        </div>
      );


      case "copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr":
        return (
          <div>
            <NavBar />
  
            <Content>
              <PaymentContainer>
                <div style={{ textAlign: "center" }}>
                  <h2 style={{ textAlign: "center" }}>
                    Obrigado por fazer a sua inscrição no: <br />{" "}
                    {slug ===
                    "copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr"
                      ? "COPA AMIZADE DE JIU JITSU"
                      : "TOLEDO INTERNATIONAL OPEN NO GI"}
                  </h2>
                  <h2 style={{ color: "red" }}>Inscrição N° {id}</h2>
                  {/* <p>Temos algumas instruções de pagamentos para você:</p> */}
                  <h1>Instruções:</h1>
                  <h3>
                    1° Efetuar o pagamento através do PIX:{" "}
                    <ul>
                      <li>
                        <strong>Banco SICOOB</strong>
                      </li>
                      <li>Vander Carlini Francisco</li>
                      <li>
                        chave PIX do tipo CPF <strong>029.948.199-96</strong>
                      </li>
                    </ul>
                    {/* <strong>
                    
                      (45) 99907-3832
                    
                  </strong>{" "} */}
                    <br />
                    2° Enviar o comprovante de pagamento para o WhatsApp:{" "}
                    <strong>
                      <br />
                      {slug === "copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr" ? (
                        <a
                          target={"blank"}
                          href={`https://whatsa.me/5544998072957/?t=Ol%C3%A1+Vander+Carlini+Francisco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+AMIZADE+DE+JIU+JITSU%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                        >
                          (44) 99807-2957
                          <FaWhatsapp />
                        </a>
                      ) : (
                        <a
                          target={"blank"}
                          href={`https://whatsa.me/5544998072957/?t=Ol%C3%A1+Vander+Carlini+Francisco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+AMIZADE+DE+JIU+JITSU%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                        >
                          (44) 99807-2957
                          <FaWhatsapp />
                        </a>
                      )}
                    </strong>{" "}
                    <br />
                    <br />
                    3° Enviar o print do numero de inscrição que você pagou para o WhatsApp:{" "}
                    <strong>
                      <br />
                      {slug === "copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr" ? (
                        <a
                          target={"blank"}
                          href={`https://whatsa.me/5544998072957/?t=Ol%C3%A1+Vander+Carlini+Francisco%2C+vou+enviar+o+print+referente+a+inscri%C3%A7%C3%A3o+no+%2ACOPA+AMIZADE+DE+JIU+JITSU+2024%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                        >
                          (44) 99807-2957
                          <FaWhatsapp />
                        </a>
                      ) : (
                        <a
                          target={"blank"}
                          href={`https://whatsa.me/5544998072957/?t=Ol%C3%A1+Vander+Carlini+Francisco%2C+vou+enviar+o+print++referente+a+inscri%C3%A7%C3%A3o+no+%2ATOLEDO+INTERNATIONAL+OPEN+NO+GI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                        >
                          (44) 99807-2957
                          <FaWhatsapp />
                        </a>
                      )}
                    </strong>{" "}
                    <br />
                    <br />
                    4° Acompanhe o status do pagamento em: <br />
                    <a href="/profile/subscription">Minhas Inscrições</a>{" "}
                  </h3>
                  {/* <img src={qrCodeCopaParana} alt="qrCodeCopaParana" /> */}
                  {/* <p>
                  Você deve enviar o comprovante de pagamento e o numero de inscrição <strong>{id}</strong> para {" "}
                  <strong>
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                    >
                      (45) 99907-3832
                      <FaWhatsapp />
                    </a>
                  </strong>
                </p> */}
                </div>
              </PaymentContainer>
            </Content>
          </div>
        );
  


    case "open-guaraniacu-brazilian-jiu-jitsu-2024-ginasio-de-esportes-onofre-garbasheski-guaraniacu-pr":
      return (
        <div>
          <NavBar />

          <Content>
            <PaymentContainer>
              <div style={{ textAlign: "center" }}>
                <h2 style={{ textAlign: "center" }}>
                  Obrigado por fazer a sua inscrição no: <br />{" "}
                  {slug ===
                  "open-guaraniacu-brazilian-jiu-jitsu-2024-ginasio-de-esportes-onofre-garbasheski-guaraniacu-pr"
                    ? "OPEN GUARANIAÇU JIU JITSU 2024"
                    : "TOLEDO INTERNATIONAL OPEN NO GI"}
                </h2>
                <h2 style={{ color: "red" }}>Inscrição N° {id}</h2>
                {/* <p>Temos algumas instruções de pagamentos para você:</p> */}
                <h1>Instruções:</h1>
                <h3>
                  1° Efetuar o pagamento através do PIX:{" "}
                  <ul>
                    <li>
                      <strong>Pag Bank</strong>
                    </li>
                    <li>Juan Cesar de Oliveira Aracati</li>
                    <li>
                      chave PIX do tipo telefone: <strong>(45) 999451302</strong>
                    </li>
                  </ul>
                  {/* <strong>
                  
                    (45) 99907-3832
                  
                </strong>{" "} */}
                  <br />
                  2° Enviar o comprovante de pagamento para o WhatsApp:{" "}
                  <strong>
                    <br />
                    {slug ===
                    "open-guaraniacu-brazilian-jiu-jitsu-2024-ginasio-de-esportes-onofre-garbasheski-guaraniacu-pr" ? (
                      <a
                        target={"blank"}
                        href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AOPEN+GUARANIAÇU+JIU+JITSU+2024%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                      >
                        (45) 99907-3832
                        <FaWhatsapp />
                      </a>
                    ) : (
                      <a
                        target={"blank"}
                        href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2ATOLEDO+INTERNATIONAL+OPEN+NO+GI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                      >
                        (45) 99907-3832
                        <FaWhatsapp />
                      </a>
                    )}
                  </strong>{" "}
                  <br />
                  <br />
                  3° Enviar o print do numero de inscrição que você pagou para o WhatsApp:{" "}
                  <strong>
                    <br />
                    {slug ===
                    "open-guaraniacu-brazilian-jiu-jitsu-2024-ginasio-de-esportes-onofre-garbasheski-guaraniacu-pr" ? (
                      <a
                        target={"blank"}
                        href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+print+referente+a+inscri%C3%A7%C3%A3o+no+%2AOPEN+GUARANIAÇU+JIU+JITSU+2024%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                      >
                        (45) 99907-3832
                        <FaWhatsapp />
                      </a>
                    ) : (
                      <a
                        target={"blank"}
                        href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+print++referente+a+inscri%C3%A7%C3%A3o+no+%2ATOLEDO+INTERNATIONAL+OPEN+NO+GI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                      >
                        (45) 99907-3832
                        <FaWhatsapp />
                      </a>
                    )}
                  </strong>{" "}
                  <br />
                  <br />
                  4° Acompanhe o status do pagamento em: <br />
                  <a href="/profile/subscription">Minhas Inscrições</a>{" "}
                </h3>
                {/* <img src={qrCodeCopaParana} alt="qrCodeCopaParana" /> */}
                {/* <p>
                Você deve enviar o comprovante de pagamento e o numero de inscrição <strong>{id}</strong> para {" "}
                <strong>
                  <a
                    target={"blank"}
                    href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                  >
                    (45) 99907-3832
                    <FaWhatsapp />
                  </a>
                </strong>
              </p> */}
              </div>
            </PaymentContainer>
          </Content>
        </div>
      );

    case "i-rei-e-rainha-da-praia-beach-boxing-manaus-amazonas-2023-manaus-amazonas":
      return (
        <div>
          <NavBar />

          <Content>
            <PaymentContainer>
              <div style={{ textAlign: "center" }}>
                <h2 style={{ textAlign: "center" }}>
                  Obrigado por fazer a sua inscrição no: <br />
                  {" VII REI E RAINHA DA PRAIA - BEACH BOXING"}
                </h2>
                <h2 style={{ color: "red" }}>Inscrição N° {id}</h2>
                {/* <p>Temos algumas instruções de pagamentos para você:</p> */}
                <h1>Instruções:</h1>
                <h3>
                  1° Efetuar o pagamento através do PIX:{" "}
                  <ul>
                    <li>
                      <strong>Itau</strong>
                    </li>
                    <li>Tasso Oliveira Alves da Rocha</li>
                    <li>
                      chave PIX do tipo telefone: <strong>(92) 98182-1004</strong>
                    </li>
                  </ul>
                  {/* <strong>
                  
                    (45) 99907-3832
                  
                </strong>{" "} */}
                  <br />
                  2° Enviar o comprovante de pagamento para o WhatsApp:{" "}
                  <strong>
                    <br />
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/5592981821004/?t=Ol%C3%A1%20Tasso%20Oliveira%20Alves%20da%20Rocha,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*I%20REI%20E%20RAINHA%20DA%20PRAIA%20-%20BEACH%20BOXING*%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                    >
                      (92) 98182-1004
                      <FaWhatsapp />
                    </a>
                  </strong>{" "}
                  <br />
                  <br />
                  3° Enviar o print do numero de inscrição que você pagou para o WhatsApp:{" "}
                  <strong>
                    <br />
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/5592981821004/?t=Ol%C3%A1%20Tasso%20Oliveira%20Alves%20da%20Rocha,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*I%20REI%20E%20RAINHA%20DA%20PRAIA%20-%20BEACH%20BOXING*%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                    >
                      (92) 98182-1004
                      <FaWhatsapp />
                    </a>
                  </strong>{" "}
                  <br />
                  <br />
                  4° Acompanhe o status do pagamento em: <br />
                  <a href="/profile/subscription">Minhas Inscrições</a>{" "}
                </h3>
                {/* <img src={qrCodeCopaParana} alt="qrCodeCopaParana" /> */}
                {/* <p>
                Você deve enviar o comprovante de pagamento e o numero de inscrição <strong>{id}</strong> para {" "}
                <strong>
                  <a
                    target={"blank"}
                    href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                  >
                    (45) 99907-3832
                    <FaWhatsapp />
                  </a>
                </strong>
              </p> */}
              </div>
            </PaymentContainer>
          </Content>
        </div>
      );

    case "1o-copa-arena-parintins-de-jiu-jitsu-ginasio-elias-assayag-parintins-am":
      return (
        <div>
          <NavBar />

          <Content>
            <PaymentContainer>
              <div style={{ textAlign: "center" }}>
                <h2 style={{ textAlign: "center" }}>
                  Obrigado por fazer a sua inscrição no: <br />
                  {"1º copa arena parintins de jiu jitsu".toLocaleUpperCase()}
                </h2>
                <h2 style={{ color: "red" }}>Inscrição N° {id}</h2>
                {/* <p>Temos algumas instruções de pagamentos para você:</p> */}
                <h1>Instruções:</h1>
                <h3>
                  1° Efetuar o pagamento através do PIX:{" "}
                  <ul>
                    <li>
                      <strong>Banco Bradesco</strong>
                    </li>
                    <li>José Michel Tavares Pio</li>
                    <li>
                      chave PIX do tipo telefone: <strong>(92) 99468-5161</strong>
                    </li>
                  </ul>
                  {/* <strong>
                  
                    (45) 99907-3832
                  
                </strong>{" "} */}
                  <br />
                  2° Enviar o comprovante de pagamento para o WhatsApp:{" "}
                  <strong>
                    <br />
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/5592994685161/?t=Ol%C3%A1%20Michel%20Tavares,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20na%20*1%C2%BA%20%20COPA%20ARENA%20PARINTINS%20DE%20JIU%20JITSU***%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                    >
                      (92) 99468-5161
                      <FaWhatsapp />
                    </a>
                  </strong>{" "}
                  <br />
                  <br />
                  3° Enviar o print do numero de inscrição que você pagou para o WhatsApp:{" "}
                  <strong>
                    <br />
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/5592994685161/?t=Ol%C3%A1%20Michel%20Tavares,%20vou%20enviar%20o%20print%20eferente%20a%20inscri%C3%A7%C3%A3o%20na%20*1%C2%BA%20%20COPA%20ARENA%20PARINTINS%20DE%20JIU%20JITSU**%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                    >
                      (92) 99468-5161
                      <FaWhatsapp />
                    </a>
                  </strong>{" "}
                  <br />
                  <br />
                  4° Acompanhe o status do pagamento em: <br />
                  <a href="/profile/subscription">Minhas Inscrições</a>{" "}
                </h3>
                {/* <img src={qrCodeCopaParana} alt="qrCodeCopaParana" /> */}
                {/* <p>
                Você deve enviar o comprovante de pagamento e o numero de inscrição <strong>{id}</strong> para {" "}
                <strong>
                  <a
                    target={"blank"}
                    href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                  >
                    (45) 99907-3832
                    <FaWhatsapp />
                  </a>
                </strong>
              </p> */}
              </div>
            </PaymentContainer>
          </Content>
        </div>
      );

    case "toledo-international-open-brazilian-jiu-jitsu-nogi-2023-ginasio-de-esportes-alcides-pan-toledo-pr":
    case "toledo-international-open-brazilian-jiu-jitsu-gi-2023-ginasio-de-esportes-alcides-pan-toledo-pr":
      return (
        <div>
          <NavBar />

          <Content>
            <PaymentContainer>
              <div style={{ textAlign: "center" }}>
                <h2 style={{ textAlign: "center" }}>
                  Obrigado por fazer a sua inscrição no: <br />{" "}
                  {slug ===
                  "toledo-international-open-brazilian-jiu-jitsu-gi-2023-ginasio-de-esportes-alcides-pan-toledo-pr"
                    ? "TOLEDO INTERNATIONAL OPEN GI"
                    : "TOLEDO INTERNATIONAL OPEN NO GI"}
                </h2>
                <h2 style={{ color: "red" }}>Inscrição N° {id}</h2>
                {/* <p>Temos algumas instruções de pagamentos para você:</p> */}
                <h1>Instruções:</h1>
                <h3>
                  1° Efetuar o pagamento através do PIX:{" "}
                  <ul>
                    <li>
                      <strong>Pag Bank</strong>
                    </li>
                    <li>Juan Cesar de Oliveira Aracati</li>
                    <li>
                      chave PIX do tipo telefone: <strong>(45) 999451302</strong>
                    </li>
                  </ul>
                  {/* <strong>
                  
                    (45) 99907-3832
                  
                </strong>{" "} */}
                  <br />
                  2° Enviar o comprovante de pagamento para o WhatsApp:{" "}
                  <strong>
                    <br />
                    {slug ===
                    "toledo-international-open-brazilian-jiu-jitsu-gi-2023-ginasio-de-esportes-alcides-pan-toledo-pr" ? (
                      <a
                        target={"blank"}
                        href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2ATOLEDO+INTERNATIONAL+OPEN+GI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                      >
                        (45) 99907-3832
                        <FaWhatsapp />
                      </a>
                    ) : (
                      <a
                        target={"blank"}
                        href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2ATOLEDO+INTERNATIONAL+OPEN+NO+GI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                      >
                        (45) 99907-3832
                        <FaWhatsapp />
                      </a>
                    )}
                  </strong>{" "}
                  <br />
                  <br />
                  3° Enviar o print do numero de inscrição que você pagou para o WhatsApp:{" "}
                  <strong>
                    <br />
                    {slug ===
                    "toledo-international-open-brazilian-jiu-jitsu-gi-2023-ginasio-de-esportes-alcides-pan-toledo-pr" ? (
                      <a
                        target={"blank"}
                        href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+print+referente+a+inscri%C3%A7%C3%A3o+no+%2ATOLEDO+INTERNATIONAL+OPEN+GI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                      >
                        (45) 99907-3832
                        <FaWhatsapp />
                      </a>
                    ) : (
                      <a
                        target={"blank"}
                        href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+print++referente+a+inscri%C3%A7%C3%A3o+no+%2ATOLEDO+INTERNATIONAL+OPEN+NO+GI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                      >
                        (45) 99907-3832
                        <FaWhatsapp />
                      </a>
                    )}
                  </strong>{" "}
                  <br />
                  <br />
                  4° Acompanhe o status do pagamento em: <br />
                  <a href="/profile/subscription">Minhas Inscrições</a>{" "}
                </h3>
                {/* <img src={qrCodeCopaParana} alt="qrCodeCopaParana" /> */}
                {/* <p>
                Você deve enviar o comprovante de pagamento e o numero de inscrição <strong>{id}</strong> para {" "}
                <strong>
                  <a
                    target={"blank"}
                    href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                  >
                    (45) 99907-3832
                    <FaWhatsapp />
                  </a>
                </strong>
              </p> */}
              </div>
            </PaymentContainer>
          </Content>
        </div>
      );
    case "vii-rei-e-rainha-da-praia-beach-wrestling-manaus-amazonas":
      return (
        <div>
          <NavBar />

          <Content>
            <PaymentContainer>
              <div style={{ textAlign: "center" }}>
                <h2 style={{ textAlign: "center" }}>
                  Obrigado por fazer a sua inscrição no: <br />
                  {" VII REI E RAINHA DA PRAIA - BEACH WRESTLING "}
                </h2>
                <h2 style={{ color: "red" }}>Inscrição N° {id}</h2>
                {/* <p>Temos algumas instruções de pagamentos para você:</p> */}
                <h1>Instruções:</h1>
                <h3>
                  1° Efetuar o pagamento através do PIX:{" "}
                  <ul>
                    <li>
                      <strong>Pic Pay</strong>
                    </li>
                    <li>Tasso Oliveira Alves da Rocha</li>
                    <li>
                      chave PIX do tipo telefone: <strong>(92) 98182-1004</strong>
                    </li>
                  </ul>
                  {/* <strong>
                  
                    (45) 99907-3832
                  
                </strong>{" "} */}
                  <br />
                  2° Enviar o comprovante de pagamento para o WhatsApp:{" "}
                  <strong>
                    <br />
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/5592981821004/?t=Ol%C3%A1%20Tasso%20Oliveira%20Alves%20da%20Rocha,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*VII%20REI%20E%20RAINHA%20DA%20PRAIA%20-%20BEACH%20WRESTLING*%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                    >
                      (92) 98182-1004
                      <FaWhatsapp />
                    </a>
                  </strong>{" "}
                  <br />
                  <br />
                  3° Enviar o print do numero de inscrição que você pagou para o WhatsApp:{" "}
                  <strong>
                    <br />
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/5592981821004/?t=Ol%C3%A1%20Tasso%20Oliveira%20Alves%20da%20Rocha,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*VII%20REI%20E%20RAINHA%20DA%20PRAIA%20-%20BEACH%20WRESTLING*%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                    >
                      (92) 98182-1004
                      <FaWhatsapp />
                    </a>
                  </strong>{" "}
                  <br />
                  <br />
                  4° Acompanhe o status do pagamento em: <br />
                  <a href="/profile/subscription">Minhas Inscrições</a>{" "}
                </h3>
                {/* <img src={qrCodeCopaParana} alt="qrCodeCopaParana" /> */}
                {/* <p>
                Você deve enviar o comprovante de pagamento e o numero de inscrição <strong>{id}</strong> para {" "}
                <strong>
                  <a
                    target={"blank"}
                    href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                  >
                    (45) 99907-3832
                    <FaWhatsapp />
                  </a>
                </strong>
              </p> */}
              </div>
            </PaymentContainer>
          </Content>
        </div>
      );

    case "xvi-copa-parana-de-jiu-jitsu":
      return (
        <div>
          <NavBar />

          <Content>
            <PaymentContainer>
              <h1>Obrigado por fazer a sua inscrição. 16° COPA PARANA DE JIU-JITSU</h1>
              <p>
                Temos algumas instruções de pagamentos para você, para efetuar o seu
                pagamento utilize as informações a seguir:
              </p>
              <ul>
                <li>
                  <strong>Caixa econômica</strong>
                </li>
                <li>Raphaela Franco X Silva</li>
                <li>Pix: (45) 99907-3832</li>
              </ul>
              <img src={qrCodeCopaParana} alt="qrCodeCopaParana" />
              <p>
                Você deve enviar o comprovante de pagamento para{" "}
                <strong>
                  <a
                    target={"blank"}
                    href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                  >
                    (45) 99907-3832
                    <FaWhatsapp />
                  </a>
                </strong>
              </p>
            </PaymentContainer>
          </Content>
        </div>
      );
    case "international-circuit-gi-de-jiu-jitsu-cascavel-pr":
    case "international-circuit-no-gi-de-jiu-jitsu-cascavel-pr":
      return (
        <div>
          <NavBar />

          <Content>
            <PaymentContainer>
              <div style={{ textAlign: "center" }}>
                <h2 style={{ textAlign: "center" }}>
                  Obrigado por fazer a sua inscrição no: <br />{" "}
                  {slug === "international-circuit-gi-de-jiu-jitsu-cascavel-pr"
                    ? "International Circuit GI de Jiu-jitsu Cascavel-PR"
                    : "International Circuit NO GI de Jiu-jitsu Cascavel-PR"}
                </h2>
                <h2 style={{ color: "red" }}>Inscrição N° {id}</h2>
                {/* <p>Temos algumas instruções de pagamentos para você:</p> */}
                <h1>Instruções:</h1>
                <h3>
                  1° Efetuar o pagamento através do PIX:{" "}
                  <ul>
                    <li>
                      <strong>Caixa econômica</strong>
                    </li>
                    <li>Juan Cesar de Oliveira Aracati</li>
                    <li>
                      chave PIX do tipo e-mail:{" "}
                      <strong>juancesardeoliveiraaracati@gmail.com</strong>
                    </li>
                  </ul>
                  {/* <strong>
                  
                    (45) 99907-3832
                  
                </strong>{" "} */}
                  <br />
                  2° Enviar o comprovante de pagamento para o WhatsApp:{" "}
                  <strong>
                    <br />
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                    >
                      (45) 99907-3832
                      <FaWhatsapp />
                    </a>
                  </strong>{" "}
                  <br />
                  <br />
                  3° Enviar o print do numero de inscrição que você pagou para o WhatsApp:{" "}
                  <strong>
                    <br />
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                    >
                      (45) 99907-3832
                      <FaWhatsapp />
                    </a>
                  </strong>{" "}
                  <br />
                  <br />
                  4° Acompanhe o status do pagamento em: <br />
                  <a href="/profile/subscription">Minhas Inscrições</a>{" "}
                </h3>
                {/* <img src={qrCodeCopaParana} alt="qrCodeCopaParana" /> */}
                {/* <p>
                Você deve enviar o comprovante de pagamento e o numero de inscrição <strong>{id}</strong> para {" "}
                <strong>
                  <a
                    target={"blank"}
                    href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                  >
                    (45) 99907-3832
                    <FaWhatsapp />
                  </a>
                </strong>
              </p> */}
              </div>
            </PaymentContainer>
          </Content>
        </div>
      );
    case "torneio-estadual-gfteam-amazonas-de-jiu-jitsu-manaus-am":
      return (
        <div>
          <NavBar />

          <Content>
            <PaymentContainer>
              <div style={{ textAlign: "center" }}>
                <h2 style={{ textAlign: "center" }}>
                  Obrigado por fazer a sua inscrição no: <br /> Torneio Estadual GFTEAM
                  Amazonas
                </h2>
                <h2 style={{ color: "red" }}>Inscrição N° {id}</h2>
                {/* <p>Temos algumas instruções de pagamentos para você:</p> */}
                <h1>Instruções:</h1>
                <h3>
                  1° Efetuar o pagamento através do PIX:{" "}
                  <ul>
                    <li>
                      <strong>Mercado Pago</strong>
                    </li>
                    <li>Raimundo Otávio Souza Carneiro</li>
                    <li>
                      Pix: <strong>581.853.302-63</strong>
                    </li>
                  </ul>
                  {/* <strong>
                
                  (45) 99907-3832
                
              </strong>{" "} */}
                  <br />
                  2° Enviar o comprovante de pagamento para o WhatsApp:{" "}
                  <strong>
                    <br />
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/559291911696/?t=Ol%C3%A1+Raimundo+Ot%C3%A1vio+Souza+Carneiro%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2ATorneio+Estadual+GFTEAM+Amazonas%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}%2A`}
                    >
                      (92) 99191-1696
                      <FaWhatsapp />
                    </a>
                  </strong>{" "}
                  <br />
                  <br />
                  3° Enviar o print do numero de inscrição que você pagou para o WhatsApp:{" "}
                  <strong>
                    <br />
                    <a
                      target={"blank"}
                      href={`https://whatsa.me/559291911696/?t=Ol%C3%A1+Raimundo+Ot%C3%A1vio+Souza+Carneiro%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2ATorneio+Estadual+GFTEAM+Amazonas%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}%2A`}
                    >
                      (92) 99191-1696
                      <FaWhatsapp />
                    </a>
                  </strong>{" "}
                  <br />
                  <br />
                  4° Acompanhe o status do pagamento em: <br />
                  <a href="/profile/subscription">Minhas Inscrições</a>{" "}
                </h3>
                {/* <img src={qrCodeCopaParana} alt="qrCodeCopaParana" /> */}
                {/* <p>
              Você deve enviar o comprovante de pagamento e o numero de inscrição <strong>{id}</strong> para {" "}
              <strong>
                <a
                  target={"blank"}
                  href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                >
                  (45) 99907-3832
                  <FaWhatsapp />
                </a>
              </strong>
            </p> */}
              </div>
            </PaymentContainer>
          </Content>
        </div>
        // <div>
        //   <NavBar />

        //   <Content>
        //     <PaymentContainer>
        //       <h1>
        //         Obrigado por fazer a sua inscrição. Torneio Estadual GFTEAM
        //         Amazonas
        //       </h1>
        //       <h2>Inscrição: {id}</h2>
        //       <p>Temos algumas instruções de pagamentos para você:</p>
        //       <h3>
        //         1° Efetuar o pagamento através do PIX:{" "}
        //         <strong>581.853.302-63 CPF</strong> <br />
        //         2° Enviar o comprovante de pagamento para o WhatsApp:{" "}
        //         <strong>
        //           <a
        //             target={"blank"}
        //             href="https://whatsa.me/559281291027/?t=Ol%C3%A1+Raimundo+Ot%C3%A1vio+Souza+Carneiro%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2ATorneio+Estadual+GFTEAM+Amazonas%2A%0A%0AInscri%C3%A7%C3%A3o+N%C2%B0+202020"
        //           >
        //             (92) 98129-1027
        //             <FaWhatsapp />
        //           </a>
        //         </strong>{" "}
        //         <br />
        //         3° Enviar o print da tela de inscrição para o WhatsApp:{" "}
        //         <strong>
        //           <a
        //             target={"blank"}
        //             href="https://whatsa.me/559281291027/?t=Ol%C3%A1+Raimundo+Ot%C3%A1vio+Souza+Carneiro%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2ATorneio+Estadual+GFTEAM+Amazonas%2A%0A%0AInscri%C3%A7%C3%A3o+N%C2%B0+202020"
        //           >
        //             (92) 98129-1027
        //             <FaWhatsapp />
        //           </a>
        //         </strong>{" "}
        //         <br />
        //       </h3>
        //       <ul>
        //         <li>
        //           <strong>Mercado Pago</strong>
        //         </li>
        //         <li>Raimundo Otávio Souza Carneiro</li>
        //         <li>
        //           Pix: <strong>581.853.302-63 CPF</strong>
        //         </li>
        //       </ul>
        //       {/* <img src={qrCodeCopaParana} alt="qrCodeCopaParana" /> */}
        //       {/* <p>
        //           Você deve enviar o comprovante de pagamento e o numero de inscrição <strong>{id}</strong> para {" "}
        //           <strong>
        //             <a
        //               target={"blank"}
        //               href={`https://whatsa.me/5545999073832/?t=Ol%C3%A1+Raphaela+Franco%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AInternational+Circuit+de+Jiu-jitsu+Cascavel-PR%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
        //             >
        //               (45) 99907-3832
        //               <FaWhatsapp />
        //             </a>
        //           </strong>
        //         </p> */}
        //     </PaymentContainer>
        //   </Content>
        // </div>
      );

    case "iii-open-terra-santa-de-jiu-jitisu":
      return (
        <div>
          <NavBar />

          <Content>
            <PaymentContainer>
              <h1>Obrigado por fazer a sua inscrição. III OPEN TERRA SANTA</h1>
              <p>
                Temos algumas instruções de pagamentos para você, para efetuar o seu
                pagamento utilize as informações a seguir:
              </p>
              <ul>
                <li>
                  <strong>Caixa econômica</strong>
                </li>
                <li>Ag: 3616</li>
                <li>Conta: 928679542-0</li>
                <li>Raimundo Sérgio Corrêa Cardoso</li>
                <li>Pix: 827.839.422-91</li>
              </ul>
              <p>
                Você deve enviar o comprovante de pagamento para{" "}
                <img src={qrCode} alt="qrCode" />
                <strong>
                  <a
                    target={"blank"}
                    href="https://whatsa.me/5592994386161/?t=Ol%C3%A1%20Sergio%20Cardoso,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*III%20OPEN%20TERRA%20SANTA%20DE%20JIU-JITSU*"
                  >
                    (92) 99438-6161
                    <FaWhatsapp />
                  </a>
                </strong>
              </p>
            </PaymentContainer>
          </Content>
        </div>
      );
    case "xxxi-copa-orley-lobato-de-jiu-jitsu-ninimbergue-guerra":
      return (
        <div>
          <NavBar />

          <Content>
            <PaymentContainer>
              <h1>
                Obrigado por fazer a sua inscrição. XXXI COPA ORLEY LOBATO DE JIU-JITSU
              </h1>
              <p>
                Temos algumas instruções de pagamentos para você, para efetuar o seu
                pagamento utilize as informações a seguir:
              </p>
              <ul>
                <li>
                  <strong>C6 Bank</strong>
                </li>
                <li>Thiago Souza Dellarmelina</li>
                <li>Pix: (92) 98129-1027</li>
              </ul>
              <img src={qrCodeOrlay} alt="qrCodeOrlay" />
              <p>
                Você deve enviar o comprovante de pagamento para{" "}
                <strong>
                  <a
                    target={"blank"}
                    href="https://whatsa.me/5592981291027/?t=Ol%C3%A1%20Thiago%20Souza%20Dellarmelina,%20vou%20enviar%20o%20comprovante%20de%20pagamento%20referente%20a%20inscri%C3%A7%C3%A3o%20no%20*XXXI%20COPA%20ORLEY%20LOBATO%20DE%20JIU-JITSU*"
                  >
                    (92) 98129-1027
                    <FaWhatsapp />
                  </a>
                </strong>
              </p>
            </PaymentContainer>
          </Content>
        </div>
      );
    default:
      return (
        <div>
          <NavBar />
        </div>
      );
  }
};

export default PaymentEnrollment;
